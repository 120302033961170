import { Spin } from "antd";
import React, { Suspense } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Main from "../../components/Main/Main";
import logogamofyy from "../../assets/images/logo.png";
import igpl from "../../assets/images/GPL.png";

const HomePage = () => {
  const params = useParams();
  console.log(params);
  const search = useLocation().search;
  let ext_id = new URLSearchParams(search).get("ext_id");
  console.log("ext_id ", ext_id);
  // if (ext_id === undefined || ext_id === null)
  //   ext_id = Math.floor(Math.random() * 1000000000);

  // console.log(ext_id);

  return (
    <StyledHomePage>
      <Suspense
        fallback={
          <>
            <Spin />
          </>
        }
      >
        {params.slug === "igpl" ? (
          <Main logo={igpl} serviceType={params.slug} ext_id={ext_id} />
        ) : (
          <Main serviceType={params.slug} logo={logogamofyy} ext_id={ext_id} />
        )}
      </Suspense>
    </StyledHomePage>
  );
};

export default HomePage;

const StyledHomePage = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
`;
