import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import userContext from "../../components/context/UserContext";
import styled from "styled-components";
import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import Spinner from "../../components/Spinner/Spinner";
import SimilarItems from "../../components/SimilarItems/SimilarItems";

const GameDescription = () => {
  const navigation = useNavigate();
  const [loader, setLoader] = useState(true);
  const [otherItems, setOtherItems] = useState([true]);
  const [selectedItem, setSelectedItem] = useState([true]);
  const params = useParams();
  console.log(params);
  const { state } = useContext(userContext);

  //   console.log(state);

  const { games } = state;
  //   console.log(games);

  //   useEffect(() => {
  //     if (games.length > 0)

  //   }, []);

  useEffect(() => {
    if (games.length > 0) {
      console.log(games.find((item) => item.gameid === params.id));

      const selectedItem = games.find((item) => item.gameid === params.id);
      setSelectedItem(selectedItem);
      const catId = games.find((item) => item.gameid === params.id).cat_id;

      //   console.log(catId);

      const otherItems = games.filter(
        (item) => item.cat_id === catId && item.gameid !== params.id
      );
      console.log(otherItems);
      setOtherItems(otherItems);
      setLoader(false);
    }
  }, [games, navigation]);

  if (loader)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <GameDescriptionStyled>
      <Link to={`/playgames/${params.id}`}>
        <div className="image-section">
          <SuspenseImg
            // style={contentStyle}
            src={selectedItem.imgurl}
            alt={selectedItem.gamename}
          />
        </div>
      </Link>
      <div className="inner-elements"></div>
      <h3>Similar Games</h3>
      <div className="game-collection ">
        <SimilarItems otherItems={otherItems} />
      </div>
    </GameDescriptionStyled>
  );
};

export default GameDescription;

const GameDescriptionStyled = styled.div`
  min-height: 100vh;

  .image-section {
    height: 30vh;

    img {
      height: 100%;
      width: 100%;
    }
  }
  background-image: linear-gradient(to right, #141e30, #243b55);
  .inner-elements {
    padding: 10px;
  }

  h3 {
    width: 90%;
    color: black;
    font-weight: 400;
    background-image: linear-gradient(to right, #0099f7, #f11712);
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
`;
