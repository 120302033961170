import React from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import userContext from "../context/UserContext";
import ErrorModal from "../ErrorModal/ErrorModal";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();

  // console.log(location);
  const [cookies, setCookie] = useCookies(["msisdn"]);
  const { state } = React.useContext(userContext);

  const { status } = state;

  console.log(cookies);

  return (
    <div>
      {!location.pathname.includes("home") ? <Header /> : null}
      {cookies.msisdn === "null" && location.pathname !== "/home" ? (
        <ErrorModal />
      ) : null}
      <div className="content">{props.children}</div>

      {!location.pathname.includes("home") ? <Footer /> : null}
    </div>
  );
};

export default Layout;
