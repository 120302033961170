import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

// import "./App.css";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  console.log("window.location hostname ", window.location.hostname);
  const navigate = useNavigate();
  console.log("window.location ", window.location.pathname.toLowerCase());
  // console.log(useContext(userContext));
  useEffect(() => {
    if (
      (window.location.hostname.toLowerCase() === "api.panzcon.com") &
      (window.location.pathname === "/")
    )
      navigate("/home/gamofyy");
    else if (
      (window.location.hostname.toLowerCase() === "igpl.panzcon.com") &
      (window.location.pathname === "/")
    )
      navigate("/home/igpl");
    else if (
      (window.location.hostname.toLowerCase() === "localhost") &
      (window.location.pathname === "/")
    )
      navigate("/home/gamofyy");
  }, [navigate]);

  console.log("window.location.href ", window.location.pathname);

  return <div></div>;
}

export default App;
