import React, { useState, useEffect } from "react";
import { Tooltip, Button } from "antd";
import axios from "axios";

import styled from "styled-components";

import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
// import bgImage from "../../assets/images/bgimage.jpg";

const Main = ({ logo, serviceType, ext_id }) => {
  let url = "";
  //   console.log("hello main");
  const [loading, setLoading] = useState(true);
  // const [token, setToken] = useState("");

  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const response = await axios.get("http://localhost:5980/redirect");
  //     console.log("respone ", response.data.accessToken);
  //   };
  //   fetchToken();
  // }, []);

  // if (ext_id === undefined || ext_id === null)
  //   ext_id = Math.floor(Math.random() * 1000000000);

  console.log("ext_id !!!!", ext_id);

  console.log("serviceType ", serviceType);

  // const uuid = Math.floor(Math.random() * 1000000000);
  if (serviceType !== "igpl")
    // this is for gameoofy
    url = `https://xcis.mobixone.co.za:9001/api/v1/web/ci/z5Tn-ouLO-YAnk-Ps90-QHEs-DMKS-zEIp-SgQC/bnrev-panzcon/mtn-za/ZA/subscribe/M7KSpPKjwr2oFvzRiFLSMry4/bnrev-panzcon-gameofyy/${Math.floor(
      Math.random() * 1000000000
    )}/EMPTY/EMPTY/`;
  // https://stgxcis.mobixone.co.za:9001/api/v1/web/ci/z5Tn-ouLO-YAnk-Ps90-QHEs-DMKS-zEIp-SgQC/bnrev-panzcon/mtn-za/ZA/subscribe/Z4qzmOCr1ki8X01fcjlOWgfk/bnrev-panzcon-igplpro/<RefRequest>/EMPTY/EMPTY/
  else
    url = `https://xcis.mobixone.co.za:9001/api/v1/web/ci/z5Tn-ouLO-YAnk-Ps90-QHEs-DMKS-zEIp-SgQC/bnrev-panzcon/mtn-za/ZA/subscribe/Z4qzmOCr1ki8X01fcjlOWgfk/bnrev-panzcon-igplpro/${Math.floor(
      Math.random() * 1000000000
    )}/EMPTY/EMPTY/`;
  console.log("url", url);

  useEffect(() => {
    console.log(ext_id);
    const asyncsendUUID = async () => {
      try {
        const response = await axios.post(`/subscription/saveUUID`, { ext_id });
        // console.log("response ", response.data);
      } catch (err) {
        console.log(err);
      }
    };
    asyncsendUUID();
  }, []);

  console.log("SSSS", serviceType !== "igpl" && ext_id !== null);

  if (serviceType === "igpl" && ext_id !== null)
    window.location.href = `https://xcis.mobixone.co.za:9001/api/v1/web/ci/z5Tn-ouLO-YAnk-Ps90-QHEs-DMKS-zEIp-SgQC/bnrev-panzcon/mtn-za/ZA/subscribe/Z4qzmOCr1ki8X01fcjlOWgfk/bnrev-panzcon-igplpro/${Math.floor(
      Math.random() * 1000000000
    )}/EMPTY/EMPTY/`;
  else if (ext_id !== null)
    window.location.href = `https://xcis.mobixone.co.za:9001/api/v1/web/ci/z5Tn-ouLO-YAnk-Ps90-QHEs-DMKS-zEIp-SgQC/bnrev-panzcon/mtn-za/ZA/subscribe/M7KSpPKjwr2oFvzRiFLSMry4/bnrev-panzcon-gameofyy/${ext_id}/EMPTY/EMPTY/`;

  if (ext_id === null)
    return (
      <MainStyled>
        <div className="inner-items">
          <SuspenseImg src={logo} alt="logo" />

          <h2>Subscription R5 Per / Day</h2>
          <Tooltip placement="topLeft" title="Subscribe" arrowPointAtCenter>
            <a href={url}>
              <Button type="primary" className="custom-button" size="large">
                Subscribe
              </Button>
            </a>
          </Tooltip>
          <div className="footer-info">
            {" "}
            <h3
              onClick={() => {
                window.location.href =
                  "https://xcis.mobixone.co.za/operator/mtn/terms-conditions/";
              }}
            >
              Terms & Conditions
            </h3>{" "}
            <h3>for help,call us 135</h3>
          </div>
        </div>
      </MainStyled>
    );
};

export default React.memo(Main);

const MainStyled = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  .custom-button {
    border-radius: 8px;
    background-color: #799f0c;
    font-weight: 800;
  }
  .inner-items {
    padding: 50px;
    display: flex;

    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }

  h2 {
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  .footer-info {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: white;
      cursor: pointer;
    }
  }
`;
