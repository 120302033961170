import { Button, Modal, Space } from "antd";
// import { useEffect } from "react";

const ErrorModal = () => {
  return (
    <Modal visible={true} title="Invalid User" closable={false} footer={null}>
      <div>
        <h3 style={{ textAlign: "center" }}>
          You are not allowed to access the portal as you are not charged
        </h3>
      </div>
    </Modal>
  );
};

export default ErrorModal;
