import React from "react";
import { Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

// import DOIRedirect from "../../containers/DOIRedirect/DOIRedirect";
import Layout from "../Layout/Layout";

import HomePage from "../../containers/HomePage/HomePage";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";
import GameDescription from "../../containers/GameDescription/GameDescription";
import PlayGamesIFrame from "../../containers/PlayGamesIFrame/PlayGamesIFrame";
const DOIRedirect = React.lazy(() =>
  import("../../containers/DOIRedirect/DOIRedirect")
); // Lazy-loaded

const Navigations = () => {
  return (
    <React.Suspense
      fallback={
        // <h1>Hwllo</h1>
        <SuspenseStyled>
          <Spinner />
        </SuspenseStyled>
      }
    >
      <CookiesProvider>
        <Layout>
          <Routes>
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route path="/home/:slug" element={<HomePage />} />
            <Route path="/game/:id" element={<GameDescription />} />
            <Route path="/redirect" element={<DOIRedirect />} />
            <Route path="/playgames/:slug" element={<PlayGamesIFrame />} />
          </Routes>
        </Layout>
      </CookiesProvider>
    </React.Suspense>
  );
};

export default Navigations;

const SuspenseStyled = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  /* background-color: yellow; */
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
